<script>
import feather from 'feather-icons';
import projects from '../data/projects';
import { useMeta } from 'vue-meta';

export default {
	name: 'Blog',
	data: () => {
		return {
            blog: {},
        };
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
    created() {
        const title = this.$route.params.title;
        this.blog = projects.find(obj => {
            return obj.title.replace(/\s/g, '-') == title;
        });
        useMeta({ 
            title: this.blog.title,
            description: this.blog.description,
        })
    },
};
</script>

<template>
		<!-- Blog -->
		<div class="container mx-auto text-gray-800 dark:text-ternary-light">
			<div class="sm:flex sm:gap-10 mt-10 sm:mt-20 justify-center">
                <!-- Blog Post -->
                <div class="w-full sm:w-3/4 text-left">
                    <!-- Blog Title -->
                    <h1 class="text-4xl font-bold text-center"> {{ blog.title }} </h1>
                    <br>
                    <!-- Author | Date -->
                    <div class="text-xl font-medium text-center" style="color: #334254"> Written By: Bo Bleyl · {{ blog.date }} · {{ blog.readTime.toString() }} min read </div>
                    <br>
                    <!-- Cover Photo -->
                    <div>
                        <img class="w-full object-cover" :src="blog.img" alt="card 1" />
                    </div>
                    <div v-for="section in blog.blog_full" :key="section.id">
                        <div class="text-2xl font-semibold">
                            {{ section.title}}
                        </div>
                        <br>
                        <div v-for="paragraph in section.body" :key="paragraph.id">
                            <div v-if="paragraph.text.includes('.jpg') || paragraph.text.includes('.png') || paragraph.text.includes('.gif')" v-html="md(paragraph.text)" class="flex justify-center py-2"></div>
                            <div v-else v-html="md(paragraph.text)" class="text-lg" />
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<style scoped></style>
