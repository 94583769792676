<script>
export default {
	props: ['client'],
};
</script>

<template>
	<div class="w-64">
		<img
			:src="client.img"
			:alt="client.title"
			class="h-44 py-5 px-10 border border-ternary-light dark:border-ternary-dark shadow-sm rounded-lg mb-8 cursor-pointer dark:bg-secondary-light"
		/>
	</div>
</template>

<style lang="scss" scoped></style>
