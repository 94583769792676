<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						`Hi I'm Bo! I'm a father, husband, and full-stack developer.`,
				},
				{
					id: 2,
					bio:
						`I've worked in a variety of frameworks and languages over the past 5 years ranging from mobile app development with Flutter, Swift & Kotlin, to python scripting, to web development in Sencha, Vue & React. I've worked with a variety of teams in various capacities and am constantly working to improve my skill set.`,
				},
				{
					id: 3,
					bio:
						`I graduated with a bachelor's in CS from BYU and have worked for a software development firm for the past 5 years building software for various Fortune 500 companies.`,
				},
				{
					id: 4,
					bio:
						`I love to teach people how to code through 1-on-1 mentorship, tutoring and my youtube channel, BleylDev.`,
				},
			],
		};
	},
};
</script>

<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About profile image -->
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="@/assets/images/profile.jpg"
				class="rounded-xl w-96"
				alt=""
			/>
		</div>

		<!-- About details -->
		<div class="w-full sm:w-3/4 text-left">
			<p
				v-for="bio in bios"
				:key="bio.id"
				class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
			>
				{{ bio.bio }}
			</p>
		</div>
	</div>
</template>
