<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-2/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light"
			>
				Master LinkedIn, Network Like a Pro, and Stay Ahead of Industry Trends
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl py-2 sm:py-0 xl:text-2xl text-center sm:text-left leading-none text-gray-600 dark:text-gray-200"
			>
				Download the eBook today!
			</p>
			<a href="https://bleyldev.gumroad.com/l/btfeu" target="_blank">
				<div class="mt-10 w-1/4 flex justify-center text-gray-500 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-200 dark:bg-ternary-dark hover:bg-gray-400 dark:hover:bg-slate-600 shadow-sm p-4 duration-500">
					Read Now
				</div>
			</a>
		</div>

		<!-- Banner right illustration -->
		<div class="w-full md:w-1/2 text-right float-right">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/ebook.png"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/ebook.png"
				alt="Developer"
			/>
		</div>
	</section>
</template>

<style scoped></style>
