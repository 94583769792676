<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectGallery from '@/components/projects/ProjectGallery.vue';
import { useMeta } from 'vue-meta';

export default {
	name: 'Home',
	setup () {
		useMeta({ title: 'Bleyl Dev - Home' })
	},
	components: {
		AppBanner,
		ProjectGallery,
	},
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />
		<ProjectGallery />
	</div>
</template>

<style scoped></style>
