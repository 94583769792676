<script>
export default {
	props: ['project'],
};
</script>

<template>
	<div class="rounded-2xl border md:shadow w-full overflow-hidden">
		<div v-if="project.url.includes('https')">
			<a :href="project.url">
				<img class="w-full h-48 object-cover" :src="project.img" alt="card 1" />
			</a>
		</div>
		<div v-else>
			<router-link :to="{path: '/blog/' + project.title.replace(/\s/g, '-')}">
				<img class="w-full h-48 object-cover" :src="project.img" alt="card 1" />
			</router-link>
		</div>
		<div class="px-4 py-4">
			<div class="text-xs text-gray-600 dark:text-ternary-light font-medium">
				{{ project.category }}
				<span class="mx-1">&bull;</span>
				<span>{{ project.date }}</span>
			</div>

			<h3 class="font-semibold text-gray-800 dark:text-ternary-light my-4 hover:underline text-lg">
				<div v-if="project.url.includes('https')">
					<a :href="project.url">
						{{ project.title }}
					</a>
				</div>
				<div v-else>
					<router-link :to="{path: '/blog/' + project.title.replace(/\s/g, '-')}">
						{{ project.title }}
					</router-link>
				</div>
			</h3>

			<div class="text-gray-700 dark:text-ternary-light">
				{{ project.description }}
			</div>
		</div>
		<div class="mt-2 pb-4">
			<div v-if="project.url.includes('https')">
				<a :href="project.url" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline"> {{ project.cta }} <span>&rarr;</span></a>
			</div>
			<div v-else>
				<router-link :to="{path: '/blog/' + project.title.replace(/\s/g, '-')}" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline">
					{{ project.cta }} <span>&rarr;</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>