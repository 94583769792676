<script>
import feather from 'feather-icons';
import AppBanner from '@/components/shared/AppBanner.vue';
import { useMeta } from 'vue-meta';

export default {
	name: 'Contact',
	setup () {
		useMeta({ title: 'Bleyl Dev - Contact' })
	},
	components: {
		AppBanner,
	},
	data: () => {
		return {
			contacts: [
				{
					id: 2,
					name: 'bleyldev@gmail.com',
					icon: 'mail',
				},
			],
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<div
		class="container mx-auto flex justify-center"
	>
		<!-- Contact details -->
		<AppBanner />
	</div>
</template>
