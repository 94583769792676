<script>
import ProjectGrid from '../components/projects/ProjectsGrid.vue';
import { CalendarHeatmap } from 'vue3-calendar-heatmap';
import dates from '../data/dates';

export default {
	name: 'Projects',
	components: {
		ProjectGrid,
		CalendarHeatmap,
	},
	data: () => {
		return {
            date: Date(),
			dates: dates,
        };
	},
	created() {
		var now = new Date();
		const nextMonth = new Date(now.getFullYear(), now.getMonth()+1, 1);
		this.date = nextMonth;
	},
};
</script>

<template>
	<div class="container mx-auto">
		<ProjectGrid />
		<div class="container">
			<div class="text-2xl font-medium py-10">Bo has written {{dates.length}} posts in 2023</div>
			<CalendarHeatmap
				class="flex text-xs justify-center items-center w-full"
				:values="dates"
				:endDate="date"
				:range-color="['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']"
				:round="5"
				:tooltip="false"
				:max="5"
				/>
		</div>
	</div>
</template>

<style scoped></style>
