<script>
import counter from 'vue3-autocounter';
import { getYoutubeData } from './YoutubeService';
export default {
	components: {
		counter,
	},
	data: () => {
		return {
			experienceTitle: 'Years of experience',
			youtubeTitle: 'Current Subs on Youtube',
			viewsTitle: 'Total Views',
			videosTitle: 'Videos Published',
			API_KEY: '',
			subscriberCount: 0,
			viewsCount: 0,
			videosCount: 0,
		};
	},
	created() {
		getYoutubeData().then((response) => {
			this.subscriberCount = Number(response.items[0].statistics.subscriberCount);
			this.viewsCount = Number(response.items[0].statistics.viewCount);
			this.videosCount = Number(response.items[0].statistics.videoCount);
		});
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm">
		<!-- About me counters -->
		<div
			class="font-general-regular container mx-auto py-20 block sm:flex sm:justify-between sm:items-center"
		>
			<!-- Years of experience counter -->
			<div class="mb-20 sm:mb-0">
				<counter
					ref="counter"
					:startAmount="0"
					:endAmount="5"
					:duration="1"
					:autoinit="true"
					class="font-general-medium text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
					aria-label="About Status Counter"
				/>
				<span
					class="block text-md text-ternary-dark dark:text-ternary-light"
				>
					{{ experienceTitle }}
				</span>
			</div>

			<!-- Youtube Subs counter -->
			<div class="mb-20 sm:mb-0">
				<counter
					ref="counter"
					:startAmount="0"
					:endAmount="subscriberCount"
					:duration="1"
					suffix="+"
					:autoinit="true"
					class="font-general-medium text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				/>
				<span
					class="block text-md text-ternary-dark dark:text-ternary-light"
					>{{ youtubeTitle }}</span
				>
			</div>

			<!-- Views Youtube Counter -->
			<div class="mb-20 sm:mb-0">
				<counter
					ref="counter"
					:startAmount="0"
					:endAmount="viewsCount"
					:duration="1"
					suffix="+"
					:autoinit="true"
					class="font-general-medium text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				/>
				<span
					class="block text-md text-ternary-dark dark:text-ternary-light"
				>
					{{ viewsTitle }}
				</span>
			</div>

			<!-- Videos Youtube Counter -->
			<div class="mb-20 sm:mb-0">
				<counter
					ref="counter"
					:startAmount="0"
					:endAmount="videosCount"
					:duration="1"
					:autoinit="true"
					class="font-general-medium text-4xl font-bold text-secondary-dark dark:text-secondary-light mb-2"
				/>
				<span
					class="block text-md text-ternary-dark dark:text-ternary-light"
				>
					{{ videosTitle }}
				</span>
			</div>
		</div>
	</div>
</template>
