<script>
import AboutClientSingle from './AboutClientSingle.vue';

export default {
	components: {
		AboutClientSingle,
	},
	data: () => {
		return {
			clientsHeading: 'Some of the Frameworks & Languages I\'ve worked in',
			clients: [
				{
					id: 1,
					title: 'NodeJS',
					img: require('@/assets/images/brands/node.png'),
				},
				{
					id: 2,
					title: 'Python',
					img: require('@/assets/images/brands/python.png'),
				},
				{
					id: 3,
					title: 'Flutter',
					img: require('@/assets/images/brands/flutter.png'),
				},
				{
					id: 4,
					title: 'VueJS',
					img: require('@/assets/images/brands/vuejs.png'),
				},
				{
					id: 5,
					title: 'ReactJS',
					img: require('@/assets/images/brands/reactjs.png'),
				},
				{
					id: 6,
					title: 'Kotlin',
					img: require('@/assets/images/brands/kotlin.png'),
				},
				{
					id: 7,
					title: 'Java',
					img: require('@/assets/images/brands/java.png'),
				},
				{
					id: 7,
					title: 'Swift',
					img: require('@/assets/images/brands/swift.png'),
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		<p
			class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-primary-light"
		>
			{{ clientsHeading }}
		</p>
		<div class="grid place-items-center grid-cols-1 sm:grid-cols-4 mt-10 sm:mt-14 gap-2 pl-6">
			<AboutClientSingle
				v-for="client in clients"
				:key="client.id"
				:client="client"
			/>
		</div>
	</div>
</template>
