// Projects data.
const projects = [
	{
		id: 19,
		date: 'June 14th, 2023',
		cta: 'Read More',
		title: 'How to Get Your First Job as a Frontend Developer',
		category: 'Resources',
		description: 'Starting a career in tech can be challenging, especially when looking for your first job in the field. It can be a stressful and daunting process, but with the right approach, tools, and mindset, you can increase your chances of success.',
		url: 'https://icodethis.com/blog/first-job-as-a-frontend-developer-2023',
		img: require('@/assets/images/bo-cover.jpg'),
	},
	{
		id: 18,
		date: 'May 1st, 2023',
		cta: 'Read More',
		title: 'Landing Your First Job In Tech',
		category: 'Resources',
		description: 'FREE Guide to improving your chances at landing your first job in tech. Learn how to optimize your LinkedIn Profile, pick projects that matter and understand current job market trends.',
		url: 'https://bleyldev.gumroad.com/l/btfeu',
		img: require('@/assets/images/ebook.png'),
	},
	{
		id: 17,
		date: 'April 27th, 2023',
		cta: 'Read More',
		title: `SL 1 - How Slack Rose From the Ashes of a Failed Game`,
		category: 'Startup Lore',
		description: `Startup Lore #1 - Have you ever heard of the online game Glitch? Probably not, because it didn't last long. Glitch was a massively multiplayer game that was meant to be weird, fun, and unlike anything else on the internet. Unfortunately, it didn't catch on with players and was shut down after just over a year.`,
		url: 'localBlogPost',
		readTime: 3,
		img: require('@/assets/images/blog/startup-lore.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `Have you ever heard of the online game Glitch? Probably not, because it didn't last long. Glitch was a massively multiplayer game that was meant to be weird, fun, and unlike anything else on the internet. Unfortunately, it didn't catch on with players and was shut down after just over a year.`,
					},
					{
						id: 2,
						text: `But Glitch's legacy lives on in an unexpected way. The team behind the game, led by Stewart Butterfield, had developed an internal tool for communication and collaboration while working on Glitch. You might have heard or used this tool before, it's called Slack.`,
					},
				]
			},
			{
				id: 2,
				title: 'Timeline',
				body: [
					{
						id: 1,
						text: `-&nbsp; 2009: The team behind Glitch develops an internal tool for communication and collaboration`,
					},
					{
						id: 2,
						text: `-&nbsp; 2011: Excess of $16 million raised in investments to build Glitch`,
					},
					{
						id: 3,
						text: `-&nbsp; 2012: Glitch is shut down after just over a year since the launch`,
					},
					{
						id: 4,
						text: `-&nbsp; 2013: The team decides to turn their communication tool into a standalone product, Slack`,
					},
					{
						id: 5,
						text: `-&nbsp; 2014: Slack launches and gains early adopters`,
					},
					{
						id: 6,
						text: `-&nbsp; 2015: Slack raises $172 million in series E round`,
					},
					{
						id: 7,
						text: `-&nbsp; 2019: Slack goes public`,
					},
					{
						id: 8,
						text: `-&nbsp; Slack is acquired by Salesforce for $27.7 billion`,
					},
					{
						id: 9,
						text: `At first, Slack (“Searchable Log of All Communication and Knowledge”) was just a side project. The team wasn't sure if it would be successful or not, but they believed in the idea and wanted to see where it could go. They started testing it with a few small teams and got positive feedback right away.`,
					},
				]
			},
			{
				id: 3,
				title: 'Early Adopters',
				body: [
					{
						id: 1,
						text: `Soon, they had hundreds of teams using Slack, including some big-name companies like Airbnb and BuzzFeed.`,
					},
					{
						id: 2,
						text: `The success of Slack was due in part to its simplicity. It was easy to use, intuitive, and had a friendly, approachable design. It was also highly customizable, which allowed teams to adapt it to their specific needs.`,
					},
				]
			},
			{
				id: 4,
				title: 'Focus on UX',
				body: [
					{
						id: 1,
						text: `But perhaps the biggest factor in Slack's success was its focus on user experience. The team behind Slack was obsessed with making sure that the product was enjoyable to use and that it made people's lives easier.`,
					},
					{
						id: 2,
						text: `Today, Slack is one of the most popular team communication platforms in the world. It's used by millions of people every day, from small startups to Fortune 500 companies. And it all started with a failed game.`,
					},
					{
						id: 3,
						text: `So what can we learn from Slack's story?`,
					},
					{
						id: 4,
						text: `-&nbsp; Failure is not always a bad thing. Sometimes, it can lead to unexpected opportunities and new directions.`,
					},
					{
						id: 5,
						text: `-&nbsp; Simplicity and user experience are key to success in today's tech landscape.`,
					},
					{
						id: 6,
						text: `-&nbsp; Sometimes the best ideas are right under your nose. If you have a tool or process that works well for your team, it may be worth exploring whether it could be turned into a standalone product.`,
					},
					{
						id: 7,
						text: `In the end, Slack's journey from failed game to a communication powerhouse is a reminder that innovation can come from anywhere, and that success often requires taking risks and embracing the unknown.`,
					},
				]
			},
		],
	},
	{
		id: 16,
		date: 'April 24th, 2023',
		cta: 'Read More',
		title: `Responsive Design: Why It's Important for Your Website`,
		category: 'Blog',
		description: `Have you ever tried to access a website on your mobile device, only to find it difficult to read or navigate? In today's digital age, responsive design has become essential for website success, and failing to implement it can lead to a frustrating user experience, lost traffic, and missed opportunities for conversions.`,
		url: 'localBlogPost',
		readTime: 3,
		img: require('@/assets/images/blog/responsive-design.avif'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `Have you ever tried to access a website on your mobile device, only to find it difficult to read or navigate? In today's digital age, responsive design has become essential for website success, and failing to implement it can lead to a frustrating user experience, lost traffic, and missed opportunities for conversions.`,
					},
					{
						id: 2,
						text: `Responsive design refers to a website's ability to be able to adapt and fit with any type of device (mobile, tablet, web, etc). In today’s day and age, it’s impossible to know exactly how your end-user will be viewing your site, and therefore essential that you have a site that is responsive to any type of device that they could be viewing your content on.`,
					},
				]
			},
			{
				id: 2,
				title: 'Benefits of Responsive Design',
				body: [
					{
						id: 1,
						text: `Responsive design’s core goal is to improve the user experience (UX). We want to make sure that when a user navigates to our site, poor design isn’t a reason that they immediately leave it. By implementing responsive design, you’ll get:`,
					},
					{
						id: 2,
						text: `-&nbsp; Increased traffic and conversions`,
					},
					{
						id: 3,
						text: `-&nbsp; Better SEO`,
					},
					{
						id: 4,
						text: `-&nbsp; Easier Maintenance`,
					},
					{
						id: 5,
						text: `Let’s break these down a little deeper.`,
					},
					{
						id: 6,
						text: `If you’re wanting to convert users and get them to engage further with your content, you should be obsessed with ways in which you can keep them on your site and reduce the number of users who navigate to it, only to bounce away. Responsive design allows a pleasing UX for your users that allows them to better engage with your site's content. If you navigated to a site on your phone and there was an overlap of page elements, the sizing of fonts made it difficult to read and other issues like this, you would immediately leave. Responsive design allows us to mitigate these issues by creating a layout that adapts based on the size of the user's screen.`,
					},
					{
						id: 7,
						text: `Another benefit as we listed above is improved SEO. By far, the number 1 search engine that you want to have good SEO on is Google Search. Google has made it no secret that they prioritize mobile-first designed websites. This, combined with the fact that your time on page metrics will increase through responsive design will help to improve the quality of your SEO and how high you rank in Google.`,
					},
					{
						id: 8,
						text: `The last main benefit of responsive design is easier maintenance. After the initial hurdles of setting up a responsive site, as you make adjustments going forward, you typically only need to update your site once, and it will be responsive and update properly for all different screen sizes. People who don’t adopt a responsive design, often have to spend extra time ensuring that their new content looks okay for all different screen sizes which result in wasted time and money.`,
					},
				]
			},
			{
				id: 3,
				title: 'Key Elements of Responsive Design',
				body: [
					{
						id: 1,
						text: `Responsive designs incorporate a few key elements:`,
					},
					{
						id: 2,
						text: `-&nbsp; Fluid Grids and Layouts`,
					},
					{
						id: 3,
						text: `-&nbsp; Flexible Images and Media`,
					},
					{
						id: 4,
						text: `-&nbsp; Media Queries`,
					},
					{
						id: 5,
						text: `-&nbsp; Mobile-First Approach`,
					},
					{
						id: 6,
						text: `The purpose of these elements is to allow your site to stretch and constrict itself into various sizes with ease. It’s important that not only does your screen adjust to the width of the device it’s on, but that all of its inner components as well are able to adapt. Most web frameworks now have supporting styling tooling such as Tailwindcss that allows you to specify how you would like your site to look based on how big of a screen you’re dealing with.`,
					},
				]
			},
			{
				id: 4,
				title: 'Tips for Implementing Responsive Design',
				body: [
					{
						id: 1,
						text: `The most important thing that you can do when it comes to responsive web design is to spend time designing and planning out your content. Design your site to be mobile first and then create what it will look like on the web.`,
					},
					{
						id: 2,
						text: `Here are a few things that you should think about when you’re planning out and implementing your responsive design:`,
					},
					{
						id: 3,
						text: `-&nbsp; Mobile-first mentality: How easy/difficult will it be for users to touch the key elements on my site on a touchscreen? Is everything still readable on a smaller device?`,
					},
					{
						id: 4,
						text: `-&nbsp; Prioritizing content: What elements do you want to be highlighted and take up the most space on each screen type?`,
					},
					{
						id: 5,
						text: `-&nbsp; Use a design tool. Personally, I like to use Figma, their base plan is free to use and you can create really clean designs with it. They even have pre-sized frames for different screen sizes so that you can ensure that the design looks good on any device.`,
					},
				]
			},
			{
				id: 5,
				title: 'Conclusion',
				body: [
					{
						id: 1,
						text: `Responsive design has become an essential element for website success in today's digital age. Its core goal of improving the user experience through increased traffic and conversions, better SEO, and easier maintenance has made it a crucial factor for businesses and individuals who want to succeed in the online world. By utilizing responsive design, you can create sites that adapt to any device with ease. Using responsive design for your site will result in a better overall experience for both your users as well as yourself.`,
					},
				]
			},
		],
	},
	{
		id: 15,
		date: 'April 20th, 2023',
		cta: 'Read More',
		title: `How Stretching Revitalized My Remote Work Routine`,
		category: 'Blog',
		description: 'I am a somewhat lazy person physically. If an exercise isn’t engaging or fun in some way, I typically have no interest in it. It’s why I was super fit leading up to college as I’d played many sports, but I am extremely out of shape now.',
		url: 'localBlogPost',
		readTime: 3,
		img: require('@/assets/images/blog/stretching.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `I am a somewhat lazy person physically. If an exercise isn’t engaging or fun in some way, I typically have no interest in it. It’s why I was super fit leading up to college as I’d played many sports, but I am extremely out of shape now. Combine this with the fact that I have had chronic lower back pain for some time now and scar tissue around my Rhomboid muscles from previous volleyball injuries in high school and it’s a recipe for a miserable remote work experience.`,
					},
					{
						id: 2,
						text: `Like many at the start of the 2020 pandemic, I became a remote worker. Confined to my desk for 8 hours a day, working while sitting on an uncomfortable chair from Walmart. `,
					},
					{
						id: 3,
						text: `![https://media.giphy.com/media/13rQ7rrTrvZXlm/giphy.gif](https://media.giphy.com/media/13rQ7rrTrvZXlm/giphy.gif)`,
					},
					{
						id: 4,
						text: `After work, I’d often find myself staying at my desk and creating youtube videos for my channel [**Bleyl Dev**](https://www.youtube.com/c/bleyldev) or playing some video games with friends. That first year took a brutal toll on my back. I would have days where I was physically unable to get work done unless I did a combination of ibuprofen and heat pads due to the pain. I ended up seeing a chiropractor after several months, and for the first time in a while, I got my back sorted out through 2 months of sessions.`,
					},
					{
						id: 5,
						text: `However, as soon as my back was feeling good again, I dropped the chiropractor, didn’t change any of my habits, and went right back to the same old again. Fast forward one more year to 2022 and my back was just as screwed up as it was the year prior. I realized that I needed to make a change. I had slowly started to utilize the ability to stand at my desk more (yes, I’d been neglecting to regularly use this feature of my standing desk for 2 years), but that wasn’t enough.`,
					},
					{
						id: 6,
						text: `That’s when I was exposed to some youtube videos of the one and only David Goggins. For those who don’t know who Goggins is, he’s arguably the most intense, insane endurance athlete this world has ever known. I was impressed and wanted to emulate just a tiny fraction of what he had in my life to help improve my physical pain. Out of all the insane things that he does with his routines, there was one thing in particular that stood out to me the most: `,
					},
					{
						id: 7,
						text: `Goggins stretches for 3 hours every single night.`,
					},
					{
						id: 8,
						text: `![https://media.giphy.com/media/PAu9jrwQnO1c2T2In9/giphy.gif](https://media.giphy.com/media/PAu9jrwQnO1c2T2In9/giphy.gif)`,
					},
					{
						id: 9,
						text: `Now, I don’t really have the time to devote 3 HOURS to stretching every night, but I figured that I could surely find the time in my nightly routine to free up 15-20 minutes. So I did that. Every single night, I would stretch for 15-20 minutes. And that’s when I started to notice it.`,
					},
					{
						id: 10,
						text: `My back pain was gone. I couldn’t say exactly how many days it took, but after a short while, I began to realize that I could get through a full day of work without any back pain whatsoever. No need for a chiropractor, no need for an insane routine or full lifestyle switch. I was able to manage just fine all from just 15-20 minutes of stretching each night.`,
					},
					{
						id: 11,
						text: `It’s very important to note that I am still a very out-of-shape person. I’m working on that currently, but I’m still 25 pounds heavier than where I want to be and am barely able to run 2 miles without falling over. I write all of this while I am taking a big swig of some Root Beer I got from the Holiday gas station up the street from my house (I’m a work in progress, cut me some slack).`,
					},
					{
						id: 12,
						text: `Remote work can be taxing on your body. How you feel at the end of the day is a direct result of how you take care of your body. For me, stretching was an incredible force in getting rid of my back pain and getting back my productivity. 15 minutes a day keeps the chiropractor away.`,
					},
				],
			},
		],
	},
	{
		id: 14,
		date: 'April 17th, 2023',
		cta: 'Read More',
		title: `The Impact of Image Size and Compression on Website Loading Speeds`,
		category: 'Blog',
		description: 'There are hundreds of reasons why your website is slow. Unnecessary plugins, poorly written code, large asset files, etc. One of the most common reasons however is image sizing. Image sizing can make a massive difference in the time it takes for your website to load and start delivering value to your user.',
		url: 'localBlogPost',
		readTime: 2,
		img: require('@/assets/images/blog/webanalytics.avif'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `There are hundreds of reasons why your website is slow. Unnecessary plugins, poorly written code, large asset files, etc. One of the most common reasons however is image sizing. Image sizing can make a massive difference in the time it takes for your website to load and start delivering value to your user. It can be the difference between waiting 1 second for a website to load or 15 seconds. And in a time where people’s impatience with technology is razor thin, every second counts.`,
					},
				],
			},
			{
				id: 2,
				title: 'Testing Image Sizing',
				body: [
					{
						id: 1,
						text: `To show exactly how big of a difference image sizing can be, I ran a simple test using the same image, compressed in 2 additional sizes. They measure in at 12.163 KB, 3.333 KB, and 0.531 KB. Typically on your site, you’ll want to aim for smaller than 200 KB per photo, but for testing purposes, I’m spinning up a webpage with nothing except our single image to get a clear measurement of loading times using the Lighthouse inspector reporting tool on Chrome.`,
					},
					{
						id: 2,
						text: `![Earth12.PNG](${require('@/assets/images/blog/earth12.jpg')})`,
					},
					{
						id: 3,
						text: `![Earth3-25.PNG](${require('@/assets/images/blog/earth3-25.jpg')})`,
					},
					{
						id: 4,
						text: `For the first 2 tests, you can see that they took 10.5 seconds and 3.4 seconds to load the image on the page. This is not good at all. You typically want to aim for lower than 1.5 s as your largest Contentful Paint or the longest amount of time it takes for your largest asset file to load.`,
					},
					{
						id: 5,
						text: `![Earthjpg](${require('@/assets/images/blog/earthjpg.jpg')})`,
					},
					{
						id: 6,
						text: `For the last test using the .5 KB file, you can see that the speed improved dramatically.`,
					},
					{
						id: 7,
						text: `You may be wondering to yourself “Well that’s all great Bo, but I don’t want to change the quality of the photo I’m displaying on my site.”`,
					},
					{
						id: 8,
						text: `The beautiful thing about image compression however is that you don’t have to. You can still retail close to the same quality of the image while compressing it down to a smaller size or different file type that compresses photos better for your websites such as .avif or .webp. You can use one of the many different image compression tools that you’ll find online with a quick Google search.`,
					},
				],
			},
			{
				id: 3,
				title: 'Summary',
				body: [
					{
						id: 1,
						text: `-&nbsp; Image sizing can make a significant difference in loading times.`,
					},
					{
						id: 2,
						text: `-&nbsp; Aim for using images smaller than 200 KB on your website.`,
					},
					{
						id: 3,
						text: `-&nbsp; Consider compressing your photos into .webp or .avif format in order to save on space without compromising on photo quality.`,
					},
					{
						id: 4,
						text: `-&nbsp; Follow me on [**LinkedIn**](https://www.linkedin.com/in/bobleyl/) for more regular tips and articles on software development and personal development for developers.`,
					},
				],
			},
		],
	},
	{
		id: 13,
		date: 'April 13th, 2023',
		cta: 'Visit Site',
		title: 'Local Brazilian Restaurant Website',
		category: 'Web Development',
		description: 'I built a website for a local Brazilian Esfiha and Açaí restaurant so that their customers can order and view information about their restaurant more easily.',
		url: 'https://box-bite.web.app/',
		img: require('@/assets/images/boxbite.jpg'),
	},
	{
		id: 12,
		date: 'April 13th, 2023',
		cta: 'Read More',
		title: `Code Easter Eggs: 7 Secrets Hidden In Programming Languages`,
		category: 'Blog',
		description: 'We all love a good easter egg level in video games. Those levels where the developers hide a secret room, passageway, or another hidden realm that only a few ever manage to find, and typically they do so by accident.',
		url: 'localBlogPost',
		readTime: 3,
		img: require('@/assets/images/blog/easter-egg.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `We all love a good easter egg level in video games. Those levels where the developers hide a secret room, passageway, or another hidden realm that only a few ever manage to find, and typically they do so by accident.`,
					},
					{
						id: 2,
						text: `Few know this, but often the developers behind programming languages create easter eggs of their own. Here are 7 that you may not have found:`,
					},
				],
			},
			{
				id: 2,
				title: 'Popular Easter Eggs',
				body: [
					{
						id: 1,
						text: `1.&nbsp; In Python, typing 'import antigravity' in the interactive shell opens up a webcomic created by the language's creator, Guido van Rossum.`,
					},
					{
						id: 2,
						text: `2.&nbsp; In SQL, using the function 'concat()' to concatenate the strings "s" and "t" with the argument "ico" produces the word "stonico", a nod to the programming language's creator, Donald D. Chamberlin.`,
					},
					{
						id: 3,
						text: `3.&nbsp; In Python, adding 'from __future __ import braces' will return a syntax error saying "not a chance" in a funny quip about Python not ever using braces or allowing for them to be used.`,
					},
					{
						id: 4,
						text: `4.&nbsp; If you try and import "this" in Python repl like so: 'import this', you will have the 'Zen of Python' returned, 19 aphorisms about Python. Interestingly, there are only 19 aphorisms found in the Zen of Python when rumor suggests there is meant to be 20. You can try and dig deeper and find the 20th yourself or just trust what the internet sleuths have found which is that there isn't a 20th and it's left to your imagination. If you really want to be entertained, go and check out this.py and see how this code was written.`,
					},
					{
						id: 5,
						text: `5.&nbsp; In linux, if you mistype the 'ls' command and instead type 'sl', you will have a giant locomotive returned in your console.`,
					},
					{
						id: 6,
						text: `6.&nbsp; Sidekiq allows you to input character emojis flipping over tables with fun responses from the program when you do such as '❨╯°□°❩╯︵┻━┻' produces "Take a deep breath and count to ten..." and more.`,
					},
					{
						id: 7,
						text: `7.&nbsp; Pry (a Ruby Gem) has a whole file dedicated to Easter Eggs. They mostly consist of poems, but there is also this beautiful one: 'pry(main)> nyan-cat'. This command triggers a call to the Gem Nyan Cat which showcases the classic Nyan Cat & Audio.`,
					},
				],
			},
			{
				id: 3,
				title: 'Create Your Own Easter Egg',
				body: [
					{
						id: 1,
						text: `Konami Code. The Konami code is an infamous sequence of button clicks that a gamer would use on a video game to unlock cheats and secret parts of the game. There are github repositories full of libraries that you can import into your project to include your very own Konami Code into your project. A cheat code as it were. The Konami Code looks like this: 'up, up, down, down, left, left, right, right, B, A'`,
					},
					{
						id: 2,
						text: `I hope you enjoyed and discovered a new easter egg! Let me know if you manage to find any others.`,
					},
				],
			},
		],
	},
	{
		id: 11,
		date: 'April 10th, 2023',
		cta: 'Read More',
		title: `The Cognitive Benefits of Learning a New Language and How It Impacts Your Brain`,
		category: 'Blog',
		description: 'In 2014 I had the unique opportunity to move to Brazil for 2 years and fully immerse myself in the Brazilian culture and the Portuguese language as a missionary for my church.',
		url: 'localBlogPost',
		readTime: 10,
		img: require('@/assets/images/blog/obrigado.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `In 2014 I had the unique opportunity to move to Brazil for 2 years and fully immerse myself in the Brazilian culture and the Portuguese language as a missionary for my church. Given the nature of why I was there, I was thrown into the deep end and given just 6 weeks of training, learning the Portuguese language, before they placed me in the middle of Recife, Brazil with several other missionaries who only spoke Portuguese. It was a complete shock to my system and an experience unlike any other.`,
					},
					{
						id: 2,
						text: `I know from first-hand experience how challenging yet rewarding learning a new language can be. During my time in Recife Brazil, I experienced firsthand the cognitive benefits of learning a new language and how it impacted my ability to create, learn, memorize, and improve my attention span. Since then I’ve learned even more about the science behind how language learning impacts our brains and why it’s such a valuable skill to develop. We are going to do a deep dive together into how you can utilize this information either for personal or professional reasons as well as discuss some tips on how to improve your ability to learn languages.`,
					},
				],
			},
			{
				id: 2,
				title: 'Fala Português?',
				body: [
					{
						id: 1,
						text: `My first experience with Portuguese was when I landed in São Paulo to go to the missionary training center (MTC). I knew for a few months I’d be going to Brazil, but I didn’t bother to learn any Portuguese leading up to then because I generally disliked studying. Even after my 6 weeks of learning the language at the MTC I still felt like I knew close to nothing about it. So there I was, a 19-year-old American with little to no speaking proficiency in the native language of Brazil, getting on a flight to go to Recife, Brazil for the next 22 months of my life.`,
					},
					{
						id: 2,
						text: `![Favela where I lived in Recife, Brazil](${require('@/assets/images/blog/favela.jpg')})`,
					},
					{
						id: 2,
						text: `To say that the first few weeks in Recife were rough would be a massive understatement. I struggled to understand ANYTHING that anyone was saying. It didn’t help that the residents in NorthEastern Brazil (where Recife is located) had strong accents as well which made it near impossible to understand.`,
					},
				],
			},
			{
				id: 3,
				title: 'Fast-Forward - December 2014',
				body: [
					{
						id: 1,
						text: `By this point, I was speaking, reading, and writing fluently in Portuguese. So well in fact that most nights I would even be speaking Portuguese instead of English in my dreams. So what happened between the time I arrived in Recife several months prior and now?`,
					},
					{
						id: 2,
						text: `The benefits of learning a new language had finally started to kick in and my brain had finally started to process Portuguese at an increasingly faster rate. My memory had improved and I was able to recall extensive vocabulary without even needing to think about it. My ability to pay attention had improved and I found it easier to identify what people were trying to say even with extremely thick accents. My creativity and energy had improved and instead of having to follow the lead of the other missionaries I was with who were native speakers, I felt more like myself and was able to take charge more often. And to top it all off I was riding a confidence high.`,
					},
					{
						id: 3,
						text: `So how exactly did my brain change as a result of learning this new language?`,
					},
				],
			},
			{
				id: 4,
				title: 'The Cognitive Benefits of Learning a New Language',
				body: [
					{
						id: 1,
						text: `**Enhanced Memory**`,
					},
					{
						id: 2,
						text: `In 2012 a [research study](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3501256/) took place that compared the ability to recall images for both monolinguals and bilinguals. The results of the study showed a clear and decisive advantage for bilinguals when it came to memory.`,
					},
					{
						id: 3,
						text: `![Citation 1](${require('@/assets/images/blog/bilinguals.jpg')})`,
					},
					{
						id: 4,
						text: `The fascinating takeaway from this study however is that not only did bilinguals perform better, but they performed better at something completely unrelated to vocabulary or language processing. It’s clear that the benefits in terms of memory go far beyond just the ability to recall additional vocabulary.`,
					},
					{
						id: 5,
						text: `**Improved Communication Skills**`,
					},
					{
						id: 6,
						text: `When I was still a scared, new arrival to Recife, I struggled for a solid month to understand anything at all of what was being said. I could say some basic things, sure, but the bulk of conversations would just go right over my head. I did however learn to understand what people were saying based on facial and body movements, tone of voice, the cadence of voice, etc. My brain ached after each intense conversation that I would have as it was working like crazy to try and understand anything at all that it could from those conversations and piece it all together.`,
					},
					{
						id: 7,
						text: `I’ve also found that I treat other people differently when they speak English that isn’t correct or with a very strong accent. I empathize with them and understand how hard it is and how hard their brains are working just to keep the conversation going.`,
					},
					{
						id: 8,
						text: `Studies have shown that empathy is both helpful for and [enhanced through](https://www.academypublication.com/issues/past/tpls/vol03/12/17.pdf) language learning.`,
					},
					{
						id: 9,
						text: `**Delayed Age-Related Cognitive Decline**`,
					},
					{
						id: 10,
						text: `One of the big illnesses that plague our society in the modern day is mental illness. Countless research has been poured into how we can reverse or stop these illnesses from taking a hold of our elderly as well as things we can do early on in life to decrease our risk.`,
					},
					{
						id: 11,
						text: `[Research](https://news.las.iastate.edu/2021/01/28/study-shows-learning-a-second-language-thwarts-onset-of-dementia/) suggests that learning a second language can delay the onset of diseases such as Alzheimer's by up to 5 years compared to only knowing one language. It doesn’t necessarily mean you won’t get Alzheimer’s, but it does mean that you will be able to extend your viable mental years longer than had you not learned a new language at all.`,
					},
					{
						id: 12,
						text: `**Increased Concentration**`,
					},
					{
						id: 13,
						text: `A [2016 Study](https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0153485) measured the increase in attention switching among language learners and non-language learners and found that there was a measurable increase among the group of language learners as well as a long-term continued increase among those who continued language learning beyond the initial phase of the study.`,
					},
					{
						id: 14,
						text: `**Boost in Creativity**`,
					},
					{
						id: 15,
						text: `Language learning can increase the creativity of both children and adults. Those who are willing to learn, adapt, and listen to learn a new language are much more likely to increase their mental capacity to be creative as well.`,
					},
				],
			},
			{
				id: 5,
				title: 'How Learning a New Language Impacts Your Brain',
				body: [
					{
						id: 1,
						text: `Learning a new language is just like heading to the gym to work out a new muscle group. When you start working out a new muscle group for the first time, you can expect to see 2 things happen:`,
					},
					{
						id: 2,
						text: `-&nbsp; Extreme soreness`,
					},
					{
						id: 3,
						text: `-&nbsp; Improved strength`,
					},
					{
						id: 4,
						text: `Just like working out a new muscle, language learning does a similar thing to your brain. At first, it can be mentally exhausting for your brain to try and put all of the pieces together, but rest assured, with each effort you put into learning a new language, you are altering the structure of your brain and increasing the capacity of certain regions of your brain to work.`,
					},
					{
						id: 5,
						text: `Some other neurological benefits that you can see as a result of learning a language include:`,
					},
					{
						id: 6,
						text: `-&nbsp; Increased gray matter in their brain`,
					},
					{
						id: 7,
						text: `-&nbsp; Increased neuroplasticity, or the brains ability to change. As we get older, this typically decreases increasing our odds of mental illness.`,
					},
					{
						id: 8,
						text: `-&nbsp; Increased brain function`,
					},
				],
			},
			{
				id: 6,
				title: 'The Importance of Language Immersion',
				body: [
					{
						id: 1,
						text: `Lets circle back to what happened between me getting dropped off in Recife and late 2014.`,
					},
					{
						id: 2,
						text: `I went from not being able to understand a word of Portuguese in a full conversation to being completely fluent in under a year. How exactly did I do that?`,
					},
					{
						id: 3,
						text: `I was FULLY immersed. Every minute of every day was spent immersed in not just the language Portuguese, but the Brazilian culture, music, etc. I was forced to adapt to my circumstances or just give up and go home. Given that I was a missionary, we would speak with over 50 new people every single day as well which expedited the learning process quite a bit since I was exposed to a variety of accents, bad grammar, good grammar, etc. Once my brain started to connect the dots, the process of becoming fluent was rapid.`,
					},
					{
						id: 4,
						text: `Now this isn’t a realistic approach for everyone. You probably aren’t able to pack up your life and spend the next 2 years of it in a foreign country only speaking the language you’re learning. But, that doesn’t mean you can’t immerse yourself in that language still. There are a variety of mini-immersion activities that you can do right where you are at such as:`,
					},
					{
						id: 5,
						text: `-&nbsp; Start listening to music in the language. This will help you get the accent down a lot better as well as be able to speak more smoothly.`,
					},
					{
						id: 6,
						text: `-&nbsp; Watch movies that you’ve seen before in a foreign language and turn on subtitles. This will help you with your vocabulary and your ability to understand the language.`,
					},
					{
						id: 7,
						text: `-&nbsp; Connect with like-minded individuals who are trying to learn YOUR native language and take turns conversing in each other's native tongue. You can find people through social groups to do this with.`,
					},
					{
						id: 8,
						text: `-&nbsp; Find local restaurants or businesses owned by natives of the language you’re learning. Go there and express your interest in their language and culture. They will be so happy and eager to get to speak a few words with you and help you practice.`,
					},
					{
						id: 9,
						text: `-&nbsp; Read books in the language. I spent ~3-5 hours a day reading in Portuguese when I was in Brazil. Reading will increase your ability to comprehend the language.`,
					},
					{
						id: 10,
						text: `Just as it’s important for you to immerse yourself in a new language when learning, it’s also important to be aware that many foreigners are trying to do the same with your native language. Have compassion and empathy for what they are doing and try to be helpful, not hurtful to them in their learning process. I was ridiculed quite a bit when in Brazil while I was learning. Mostly because I was an easy target being the only blonde man in the favelas of Recife, but also because I couldn’t speak great at first. It can make you second-guess if you really want to continue learning.  So be mindful of what your words and reaction to others learning languages can have on them.`,
					},
				],
			},
			{
				id: 7,
				title: 'Practical Tips for Learning a New Language',
				body: [
					{
						id: 1,
						text: `Learning a language is hard. And for someone like me who hated studying language to begin with, it can be even harder. Here are some practical tips that you can implement to better learn a language and improve your life.`,
					},
					{
						id: 2,
						text: `-&nbsp; Immerse yourself. See the points I made in the above section as these will have a massive impact on your ability to learn quickly.`,
					},
					{
						id: 3,
						text: `-&nbsp; Practice daily. Find someone you can practice speaking with or get an app such as DuoLingo to assist you in daily practice. It does your body no good if you work out once and then don’t work out again for another couple of weeks. You need to make a consistent routine of practicing and working on it.`,
					},
					{
						id: 4,
						text: `-&nbsp; Focus on your ability to comprehend and communicate the basics. Over time you will become more proficient. What’s important starting out is that you can follow a conversation and communicate basic responses. Don’t worry about perfection.`,
					},
					{
						id: 5,
						text: `-&nbsp; Find a language partner. Find someone either native to the language or someone who is learning the same language that you can converse with regularly.`,
					},
					{
						id: 6,
						text: `-&nbsp; Learn the culture. Culture can affect language quite a bit. It also brings more value to what you are learning if you can also understand the intricacies of the culture.`,
					},
				],
			},
			{
				id: 8,
				title: 'Conclusion',
				body: [
					{
						id: 1,
						text: `Learning a new language offers numerous cognitive benefits to the brain, including improved memory, communication skills, delayed cognitive decline, and increased concentration. I experienced these benefits firsthand during my 2-year immersion in the Brazilian culture and language. Language learning positively impacts the brain's ability to create, learn, memorize, and improve attention span. The benefits of language learning extend beyond just being able to communicate in a foreign language, it also offers valuable skills for personal and professional development. Language learning is a tool to enhance cognitive function and overall brain health.`,
					},
					{
						id: 2,
						text: `**Citations:**`,
					},
					{
						id: 3,
						text: `1. Schroeder SR, Marian V. A Bilingual Advantage for Episodic Memory in Older Adults. J Cogn Psychol (Hove). 2012 Aug 1;24(5):591-601. doi: 10.1080/20445911.2012.669367. Epub 2012 Jun 13. PMID: 23175648; PMCID: PMC3501256.`,
					},
					{
						id: 4,
						text: `2. Bak TH, Long MR, Vega-Mendoza M, Sorace A (2016) Novelty, Challenge, and Practice: The Impact of Intensive Language Learning on Attentional Functions. PLOS ONE 11(4): e0153485.`,
					},
				],
			},
		],
	},
	{
		id: 10,
		date: 'April 6th, 2023',
		cta: 'Read More',
		title: `Why a Perfect Lighthouse SEO Score Doesn't Guarantee Great SEO`,
		category: 'Blog',
		description: 'Search engine optimization (SEO) is a crucial component of digital marketing. Lighthouse, a Google-developed open-source tool, is commonly used to evaluate website performance and identify areas that need improvement.',
		url: 'localBlogPost',
		readTime: 4,
		img: require('@/assets/images/seo-lighthouse.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `Search engine optimization (SEO) is a crucial component of digital marketing. Lighthouse, a Google-developed open-source tool, is commonly used to evaluate website performance and identify areas that need improvement. Many thought that when Google released Lighthouse, there would no longer be a need for SEO specialists and additional efforts outside of getting a good Lighthouse score. Little did they know that couldn’t be further from the truth.`,
					},
					{
						id: 2,
						text: `Lighthouse generates a score ranging from 0 to 100 based on several criteria, including accessibility, performance, best practices, and SEO. Typically when you are building a website, you want to aim to have a score of 100 in each of these categories, however, “a high Lighthouse SEO score doesn’t mean a site will have good organic rankings.” ([**Practical Commerce**](https://www.practicalecommerce.com/google-lighthouse-seo-scores-of-every-shopify-theme))`,
					},
				],
			},
			{
				id: 2,
				title: 'Quality Matters',
				body: [
					{
						id: 1,
						text: `Lighthouse focuses on technical SEO factors that do not necessarily reflect the quality of a website's content. Technical SEO optimizes the technical aspects of a site to increase SEO, such as page loading times, meta tags, and how easy a site is to crawl ([**Big Commerce**](https://www.bigcommerce.com/articles/ecommerce/technical-seo/)). While technical SEO is essential, it is not the only factor that influences SEO. The quality of content is also a crucial factor that search engines use to determine a website's relevance to a user's search query. Even if a website has a perfect Lighthouse SEO score, it may not rank well in search engine results if its content is poor or irrelevant to users' search queries.`,
					},
					{
						id: 2,
						text: `For example, keywords play a crucial role in SEO. Keywords are the words or phrases that users type into search engines to find relevant information. You may have a score of 100 for your Lighthouse SEO, but if you are using keywords that aren’t relevant to your audience, you may never reach them. Lighthouse does not account for the competitiveness of keywords that a website is targeting. Some keywords are more competitive than others, meaning that many other websites are targeting them, making it harder for a website to rank for them. While a website may have a perfect Lighthouse SEO score, it may struggle to rank well for highly competitive keywords, even with strong content and off-page SEO.`,
					},
				],
			},
			{
				id: 3,
				title: 'External Factors',
				body: [
					{
						id: 1,
						text: `Another limitation of Lighthouse is that it does not take into account off-page SEO factors, such as backlinks and social signals. Off-page SEO refers to the measures taken outside of a website to improve its search engine rankings. Backlinks, for instance, are links from other websites to a particular website, which signal to search engines that the website is a reliable and authoritative source of information. Social signals, such as likes, shares, and comments, also indicate that a website's content is valuable and relevant to users. A website with a perfect Lighthouse SEO score may still struggle to rank well if it lacks strong off-page SEO factors.`,
					},
					{
						id: 2,
						text: `You can improve your backlinks to your site by posting references to your site on social media platforms, linking to your site from your company's LinkedIn page, and seeking partnerships with other sites to get them to put links to your site on theirs (for example: maybe through writing a blog piece for them as an industry expert). Sites such as [helpareporter.com](https://helpareporter.com) can be a great source for offering up your expertise.`,
					},
				],
			},
			{
				id: 4,
				title: 'Performance & UX Matter',
				body: [
					{
						id: 1,
						text: `Another issue with relying solely on Lighthouse for SEO evaluation is that it does not consider user experience factors. User experience (UX) refers to the overall experience that users have when interacting with a website. UX includes factors such as website design, ease of navigation, and page load speed. A website may have a perfect Lighthouse SEO score but still provide a poor user experience, leading to high bounce rates and low conversion rates.`,
					},
					{
						id: 2,
						text: `One immediate tool you can use is the Lighthouse Performance score. Since site performance is typically influenced almost entirely by technical elements that Lighthouse is able to monitor, using this score and applying the proper changes accordingly is a great way to get your site looking professional and fast quickly.`,
					},
				],
			},
			{
				id: 5,
				title: 'Verdict',
				body: [
					{
						id: 1,
						text: `Lighthouse is an excellent tool to help direct your SEO efforts. It provides you with key information crucial to know whether or not Google and other search engines will rank your site properly. However, there are some serious limitations in regard to the feedback and information that it can give you as to the quality of your SEO efforts. While getting a score of 100 is excellent, it cannot replace the additional efforts needed for effective SEO such as backlinks, content creation, keyword optimization, etc.`,
					},
				],
			},
		],
	},
	{
		id: 9,
		date: 'April 3rd, 2023',
		cta: 'Read More',
		title: 'The Risks of Using ChatGPT: How Its Flawed Dataset Can Make You Look Foolish',
		category: 'Blog',
		description: `In an age of information overload and strong opinions, it's more important than ever to prioritize accuracy and credibility. Unfortunately, relying on ChatGPT's flawed dataset could actually have the opposite effect.`,
		url: 'localBlogPost',
		readTime: 4,
		img: require('@/assets/images/blog/chatgpt-foolish.jpg'),
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `In an age of information overload and strong opinions, it's more important than ever to prioritize accuracy and credibility. Unfortunately, relying on ChatGPT's flawed dataset could actually have the opposite effect.`,
					},
					{
						id: 2,
						text: `For those who haven’t caught the recent wave, ChatGPT is an AI language model, created by OpenAI, that’s gained widespread popularity due to its ability to answer almost any question in an indistinguishable manner from a human.`,
					},
					{
						id: 3,
						text: `Recently I read a fascinating Op-ed piece on ChatGPT titled “[**ChatGPT and the Enshittening of Knowledge**](https://castlebridge.ie/insights/llms-and-the-enshittening-of-knowledge/)”. The piece talks about the nature of ChatGPT’s dataset and how it’s set up to further propagate whatever bad information it puts out into the world through people copying its responses and publishing it as their own. It sparked my curiosity quite a bit and made me look a little closer at where exactly this data is coming from, why this type of dataset was chosen in the first place, and what problems are prone to surface due to these choices in OpenAI’s data source.`,
					},
				],
			},
			{
				id: 2,
				title: 'ChatGPT’s Dirty Secret',
				body: [
					{
						id: 1,
						text: `Let's take a closer look at where ChatGPT gained its extensive knowledge. According to OpenAI when talking about ChatGPT’s [**source of knowledge**](https://help.openai.com/en/articles/6783457-chatgpt-general-faq), they said, “These models were trained on vast amounts of data from the internet written by humans, including conversations, so the responses it provides may sound human-like.”`,
					},
					{
						id: 2,
						text: `If you directly ask ChatGPT about its language source, it will confirm the above statement and also affirm that the primary purpose when it was being taught was, “to develop my ability to generate human-like responses to various prompts, and to do so in a way that is fluent and natural-sounding.”. OpenAI even states on its website that “outputs may be inaccurate, untruthful, and otherwise misleading at times.”.`,
					},
					{
						id: 3,
						text: `Despite this, many people seem to rely on ChatGPT as a source of knowledge, even though the training dataset used to create it may contain inaccuracies. This is due to the fact that ChatGPT is an incredibly impressive language model. Its responses convey a high level of grammatical accuracy, which can make them seem factual, even if they are not.`,
					},
					{
						id: 4,
						text: `This poses a significant problem for us, as the information provided by ChatGPT can be misleading, and users may not realize that they need to fact-check the responses they receive.`,
					},
				],
			},
			{
				id: 3,
				title: 'The Risks of Using ChatGPT',
				body: [
					{
						id: 1,
						text: `For most use cases, utilizing ChatGPT is fairly harmless and void of any significant consequences should you copy/modify its responses. However, due to the flawed data source discussed previously, there are many use cases that could leave you looking foolish, unprofessional, or lazy.`,
					},
					{
						id: 2,
						text: `Many people have started adopting ChatGPT into their daily work to assist in completing tasks and creating responses for various matters. This type of usage can make people very casual in how they utilize ChatGPT daily and result in them neglecting to fact-check ChatGPT.`,
					},
					{
						id: 3,
						text: `For example, I had the following conversation with ChatGPT:`,
					},
					{
						id: 4,
						text: `![@/assets/images/blog/chatgpt-prompt](${require('@/assets/images/blog/chatgpt-prompt.png')})`,
					},
					{
						id: 5,
						text: `If you search the web for this artist named Bo Bleyl, you will find absolutely nothing (just a whole lot of stuff about me). Due to the nature in which I asked ChatGPT this question, it decided to make up a random bio and spit it out to me. However, if you read its response, it does this with such cohesiveness and conviction that it’s near impossible to tell if this is legit or not without doing some research and realizing that it’s completely made up.`,
					},
					{
						id: 6,
						text: `You can clearly see why this could be so problematic. ChatGPT is an incredible language model, but it cannot be trusted when it comes to the facts. And the reliability of future versions should be put even more into question as future versions will most likely account for hundreds of thousands of examples of writing that ChatGPT wrote itself.`,
					},
				],
			},
			{
				id: 4,
				title: 'Introducing Credibility',
				body: [
					{
						id: 1,
						text: `So how do we fix this problem of presenting accurate information when utilizing ChatGPT? There are already quite a few new tools out there to assist in detecting whether or not a given text originated from ChatGPT such as [**AI Text Classifier**](https://platform.openai.com/ai-text-classifier) by OpenAI. I have no doubt that in the coming months, these types of tools and plugins will become even more common. However, in the meantime, it's important to do your own fact-checking as you would have done before ChatGPT, using Google and other knowledge databases to verify the information.`,
					},
					{
						id: 2,
						text: `For questions where you're seeking answers, it may be better to Google your question instead of relying solely on ChatGPT. This will allow you to see a range of opinions and facts on the matter, rather than just relying on one source. Remember that ChatGPT is a tool that can be useful, but there is a time and place for everything. By taking responsibility for your own fact-checking, you can ensure that the information you're presenting is accurate and credible.`,
					},
				],
			},
			{
				id: 5,
				title: 'Conclusion',
				body: [
					{
						id: 1,
						text: `As incredible a tool as ChatGPT is, there is far too much hype and far too much credibility being given to it. It’s important to look back at what OpenAI, the makers of ChatGPT state on their website regarding it, “outputs may be inaccurate, untruthful, and otherwise misleading at times”. We need to proceed with caution and ensure that whatever information we are putting out there that was created through or in coordination with ChatGPT, is fact-checked and accurate, otherwise, you may end up looking foolish.`,
					},
				],
			},
		],
	},
	{
		id: 8,
		date: 'March 30th, 2023',
		cta: 'Read More',
		title: 'The Surprising Results of My Neglected 5K Subscriber YouTube Channel',
		category: 'Blog',
		readTime: 3,
		url: 'localBlogPost',
		img: require('@/assets/images/1-year-later.jpg'),
		description: 'Have you ever started a project and then neglected it, only to be surprised by the results? That’s what happened with my YouTube channel. In 2020, I found myself with a lot of free time and not many hobbies to fill it.',
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `Have you ever started a project and then neglected it, only to be surprised by the results? That’s what happened with my YouTube channel. In 2020, I found myself with a lot of free time and not many hobbies to fill it. So, I decided to start a channel called BleylDev, where I posted videos twice a week about the programming framework Flutter. My channel quickly gained traction, and I even got featured on FreeCodeCamp.org’s main YouTube channel and wrote articles on Flutter for various publications.`,
					},
					{
						id: 2,
						text: `But fast forward to 2022, when my wife and I were ready to move on from the small town we had been living in for her PA program. I had accumulated a significant number of subscribers, but I no longer had the free time or the passion to continue making videos. It has now been a year since my last upload, but you would be surprised to know that my channel has managed to maintain its growth despite my neglect. Here's a breakdown of where I left things and where they are now.`,
					},
				],
			},
			{
				id: 2,
				title: 'Subscribers',
				body: [
					{
						id: 1,
						text: `Total 1 year ago: **4,745**  
						Today: **5,304**  
						Growth: **+1.53 Subs / Day**`,
					},
					{
						id: 2,
						text: `Despite my channel being abandoned for a year, I was surprised to find that it continued to grow steadily. My total subscribers one year ago was **4,745** and today, that number has increased to **5,304**. That's a growth rate of **+1.53 subs per day**. It's amazing to see that even though I stopped creating content, my existing videos continued to perform well and attract traffic to my channel, resulting in an increase in subscribers.`,
					},
				],
			},
			{
				id: 3,
				title: 'Views',
				body: [
					{
						id: 1,
						text: `Previous Year Total Views: **213,264**  
						Past Year: **115,983**  
						Daily Average: **318 / Day**`,
					},
					{
						id: 2,
						text: `Out of all my metrics, the drop in views has been the most dramatic. In the previous year, my channel received a total of **213,264** views, while in the past year, that number decreased to **115,983**. That's a daily average of **318 views per day**.`,
					},
					{
						id: 3,
						text: `The lack of new content on my channel has impacted my views significantly. However, I was encouraged to see that the daily views and subscribers gained over the past year remained fairly consistent. While there has been a slight drop in the monthly stats, it's understandable considering how long I left the channel neglected. Despite the drop in views, I'm still pleased to see that my channel is continuing to attract a steady stream of viewers.`,
					},
					{
						id: 4,
						text: `![@/assets/images/subs-gained.png](${require('@/assets/images/blog/subs-gained.png')})`,
					},
				],
			},
			{
				id: 4,
				title: 'Revenue',
				body: [
					{
						id: 1,
						text: `Previous Year Revenue: $**317.07**  
						Past Year: **$130.40**  
						Daily Average: **$0.36 / Day**`,
					},
					{
						id: 2,
						text: `Looking at the revenue generated by my channel is a bit disheartening. In the previous year, my channel earned **$317.07** in revenue, but in the past year, that number dropped significantly to **$130.40**. That's a daily average of just **$0.36 per day** compared to **$0.87 per day** the previous year.`,
					},
					{
						id: 3,
						text: `Seeing such a low revenue number, especially during tax season, was disappointing. Instead of building off the previous year's gains, I took a significant hit and didn't earn much from my channel. It's made me question the worth of having a YouTube channel as a passive income source, as the amount of time and effort needed to maintain it may not always be worth the reward.`,
					},
					{
						id: 4,
						text: `![@/assets/images/revenue-gained.png](${require('@/assets/images/blog/revenue-gained.png')})`,
					},
				],
			},
			{
				id: 5,
				title: 'Other Interesting Stats',
				body: [
					{
						id: 1,
						text: `There's one metric that stood out to me as having a noticeable upside to not posting any new videos: the click-through-rate (CTR). It seems that by not putting out any new content, the impressions I received tended to come from people who were directly searching for videos on the topics I had covered. This had a positive impact on my CTR.`,
					},
					{
						id: 2,
						text: `![@/assets/images/click-through.png](${require('@/assets/images/blog/click-through.png')})`,
					},
				],
			},
			{
				id: 6,
				title: 'Conclusion',
				body: [
					{
						id: 1,
						text: `Is growing a YouTube channel only to abandon it a viable source of passive income? While my experience suggests that it might be possible to earn a small amount of revenue even without active channel management, it's hard to say for sure. With only 5,000 subscribers, my channel wasn't generating a significant amount of income. However, if I had continued for another year or two, I might have been able to grow the channel to 25,000 subscribers and earn between $500 to $1,000 per year. While this may not seem like much, it could still be a worthwhile hobby to pursue and a potential income source to build upon in the future.`,
					},
					{
						id: 2,
						text: `As a data junkie, I find the statistics and analytics of YouTube channel growth and engagement fascinating. I hope my experience and the metrics I've shared have been helpful to you in some way. If you have any questions or are interested in starting your own YouTube channel, feel free to [**reach out to me**](https://mentorcruise.com/mentor/bobleyl/). I'd be happy to help!`,
					},
				],
			},
		],
	},
	{
		id: 7,
		date: 'March 24, 2023',
		cta: 'Read Article',
		title: 'Why Learning a Little Bit of Code is Useful for Everyone',
		category: 'Blog',
		readTime: 7,
		description: 'In our current digital age, coding has become an essential skill that can offer numerous benefits to anyone who learns it. It doesn\'t matter if you are a student, a business professional, or someone who wants to broaden their skill set, the ability to code can unlock a world of possibilities.',
		blog_full: [
			{
				id: 1,
				title: '',
				body: [
					{
						id: 1,
						text: `In our current digital age, coding has become an essential skill that can offer numerous benefits to anyone who learns it. It doesn't matter if you are a student, a business professional, or someone who wants to broaden their skill set, the ability to code can unlock a world of possibilities. By learning the basics of coding, you can gain a better understanding of the technology that surrounds us and take control of your digital life. Everyone, regardless of their background or career aspirations, should consider learning how to code.`,
					},
				],
			},
			{
				id: 2,
				title: 'The benefits of learning to code',
				body: [
					{
						id: 1,
						text: `Learning even just a little bit of code can have a wide range of benefits. First and foremost, it can greatly improve your problem-solving skills. Coding requires thinking logically and breaking down complex problems into smaller, more manageable parts. This skill can be applied to all aspects of life and work. Coding can also increase your efficiency in daily tasks. Whether you're automating a repetitive task or writing a script to analyze data, knowing how to code can save you time and effort.`,
					},
					{	
						id: 2,
						text: `Learning the basics of programming can provide a better understanding of how technology works. This knowledge can help you make more informed decisions about the software and devices you use on a daily basis. It can also give you the foundation to dive deeper into more advanced coding concepts and languages.`,
					},
					{
						id: 3,
						text: `Another benefit of learning to code is the ability to communicate better with developers. If you're working on a project with a developer, a basic understanding of programming concepts and terminology can make communication much smoother and more effective. This can lead to a better final product and a more efficient workflow.`,
					},
					{
						id: 4,
						text: `Finally, learning to code can enhance your creativity and innovation. The ability to create something from scratch using code can be incredibly empowering and satisfying. It can also help you think outside the box and come up with unique solutions to problems. Overall, the benefits of learning even a little bit of code are numerous and can have a positive impact on both your personal and professional life.`,
					},
				],
			},
			{
				id: 3,
				title: 'Real-world applications',
				body: [
					{
						id: 1,
						text: `Coding skills can be applied in a wide variety of fields and industries. For instance, in the business world, the ability to code can be incredibly valuable for entrepreneurs and startups. By knowing how to create a basic website or build a simple app, entrepreneurs can better communicate their ideas to potential investors and customers. Moreover, coding can help businesses automate processes, reduce costs, and improve efficiency.`,
					},
					{	
						id: 2,
						text: `In education, coding has become increasingly important as technology continues to play a larger role in the classroom. Many schools and universities are now offering coding classes as part of their curriculum, recognizing the value of teaching students these skills. Most school districts don’t have enough trained teachers to be able to teach these skills and offer incentives to teachers who are capable of taking on these courses. In addition, educators can use coding to create custom teaching materials and interactive learning experiences, thus reducing their workload.`,
					},
					{
						id: 3,
						text: `In science and research, coding is essential for data analysis, simulation, and modeling. Researchers use programming languages to develop algorithms and analyze large data sets. By using code to automate tasks and conduct experiments, researchers can save time and improve the accuracy of their findings.`,
					},
					{
						id: 4,
						text: `Coding skills can also be applied in personal projects and hobbies. Whether you're building a website for your small business or creating a mobile app for a hobby project, coding skills can be incredibly valuable. Knowing how to code can help you bring your ideas to life and create something that you're proud of.`,
					},
					{
						id: 5,
						text: `In conclusion, coding skills are applicable in a wide variety of fields and can open up many doors for personal and professional development. Regardless of your background or career goals, learning to code can be a valuable investment in your future.`,
					},
				],
			},
			{
				id: 4,
				title: 'How to get started',
				body: [
					{
						id: 1,
						text: `If you're interested in learning to code, there are a plethora of resources available to you. Online tutorials and courses are a great place to start, with platforms like FreeCodeCamp and Udemy offering courses in a wide variety of coding languages and concepts. Many of these courses are free or low-cost, making them accessible to anyone with an internet connection.`,
					},
					{
						id: 2,
						text: `Another option is to enroll in a coding boot camp. These intensive programs offer a more immersive learning experience, often culminating in a final project that showcases your newly acquired skills. While boot camps can be more expensive than online courses, they offer a more structured and supportive learning environment. It’s important to do your research when considering a BootCamp however because many charge a large amount of money, but offer a rather incomplete experience or overpromise and underdeliver.`,
					},
					{
						id: 3,
						text: `Community college classes are another option for those looking to learn to code. Many community colleges offer courses in programming languages and computer science concepts at a fraction of the cost of a traditional university.`,
					},
					{
						id: 4,
						text: `If you're on a tight budget, there are also many free coding resources available online, including books, videos, and tutorials. Sites like FreeCodeCamp and Youtube offer a wealth of information and support for aspiring coders.`,
					},
					{
						id: 5,
						text: `For those who prefer a more direct, engaging way to learn, there are thousands of mentors out there who are willing to help mentor you on a regular basis and help you reach your coding goals. Sites such as [**MentorCruise**](https://mentorcruise.com/mentor/bobleyl/) can provide you access to these mentors.`,
					},
					{
						id: 6,
						text: `Overall, there are many resources available to help you learn to code, regardless of your budget or schedule. With a little bit of effort and dedication, anyone can acquire the skills needed to become a proficient coder.`,
					},
				]
			},
			{
				id: 5,
				title: 'Tips on learning to code',
				body: [
					{
						id: 1,
						text: `Learning to code can seem daunting, especially if you have no prior experience with programming. However, by taking a step-by-step approach, you can develop the skills needed to code effectively. Here are some tips to help you get started:`,
					},
					{
						id: 2,
						text: `1. Set realistic goals: Before you begin learning to code, it's important to set realistic goals. Start by identifying why you want to learn to code and what you hope to achieve. For example, if you're interested in building a website, your goal might be to learn HTML, CSS, and JavaScript. By setting clear goals, you can track your progress and stay motivated.`,
					},
					{
						id: 3,
						text: `2. Practice consistently: Consistent practice is key to learning to code. Try to schedule a regular time each day or week to work on your coding skills. This could be as little as 10 or 15 minutes per day, but the important thing is to stick to your schedule. By practicing consistently, you'll build momentum and begin to see progress.`,
					},
					{
						id: 4,
						text: `3. Join coding communities for support and guidance: Coding communities can be a valuable resource for beginners. Look for online communities where you can ask questions, get feedback on your code, and connect with other learners. You'll find that many experienced coders are happy to help beginners and offer guidance along the way. I personally recommend joining Danny Thompson’s Discord community if you’re new to coding: [https://discord.com/invite/StwJYeq]`,
					},
					{
						id: 5,
						text: `4. Attend coding events and meetups: Attending coding events and meetups is another excellent way to connect with other coders and learn new skills. Look for local coding events or meetups in your area, or consider attending online events if there aren't any in-person events available. You'll have the opportunity to network, learn from others, and gain new insights into coding.`,
					},
					{
						id: 6,
						text: `5. Embrace failure as part of the learning process: Finally, it's important to embrace failure as part of the learning process. You're not going to get everything right the first time you try it, and that's okay. In fact, making mistakes and learning from them is a critical part of becoming a successful coder. Don't be afraid to experiment, try new things, and make mistakes along the way. By doing so, you'll become a more confident and capable coder over time.`,
					},
				]
			},
			{
				id: 6,
				title: 'Summary',
				body: [
					{
						id: 1,
						text: `Learning to code can have numerous benefits in today's world, including improved problem-solving skills, increased efficiency, and enhanced creativity. Coding skills can be applied in various fields, from business and education to science and personal projects. With the abundance of resources available, including online tutorials, coding boot camps, and free coding resources, there's never been a better time to learn to code. By setting realistic goals, practicing consistently, joining coding communities, attending events, and embracing failure as part of the learning process, anyone can develop the skills needed to code effectively. I encourage readers to give coding a try, regardless of their background or experience, and take advantage of the many resources available to them. Start with small steps, learn the basics, and gradually build your skills. Who knows, you may even discover a new passion or career path!`,
					},
				]
			},
			{
				id: 7,
				title: 'Become a mentor',
				body: [
					{
						id: 1,
						text: `If you feel that you are already a skilled developer and have a lot to offer to other developers, consider [**becoming a mentor**](http://mentorcruise.com/referrals/3YZSI173x8OkorJKOp0aOMRi0q2INRRNsFpaigBm/) and helping others reach their goals of becoming a developer as well.`,
					},
				]
			},
		],
		url: 'localBlogPost',
		img: require('@/assets/images/learn-to-code.jpg'),
	},
	{
		id: 6,
		date: 'February 23, 2023',
		cta: 'Read Article',
		title: 'Custom Application Development: What You Need to Know',
		category: 'Blog',
		description: 'Have you heard the term "custom app development" before but aren’t sure what it means? According to EASA, “custom application development is the process of designing, creating and deploying tailor-made software applications for specific users.”',
		url: 'https://www.enterbridge.com/blog/custom-application-development-what-you-need-to-know',
		img: require('@/assets/images/custom-development-blog.avif'),
	},
	{
		id: 5,
		date: 'April 15th, 2022',
		cta: 'Watch Now',
		title: 'A Bot That Tells My Boss If I\'m Late To Work',
		category: 'Youtube',
		description: 'I built a script that notifies my boss if I\'m not at my desk. I built it using Python, OpenCV, NodeJS, and a handful of other libraries!',
		url: 'https://www.youtube.com/watch?v=AV7qLsYnOWY',
		img: require('@/assets/images/youre-fired-video.avif'),
	},
	{
		id: 4,
		date: 'April 8th, 2021',
		cta: 'Watch Now',
		title: 'Full Flutter Tutorial For Beginners: 2021',
		category: 'Youtube',
		description: 'This tutorial goes through all of the basics of Flutter that you need to know to create your first Flutter app! From Flutter setup, to widget trees, to data input and routing. Be sure to subscribe to the channel for weekly Flutter content.',
		url: 'https://www.youtube.com/watch?v=b-NS_5za44c',
		img: require('@/assets/images/flutter-course-video.avif'),
	},
	{
		id: 3,
		date: 'Oct 19, 2020',
		cta: 'Watch Now',
		title: 'Flutter App with Firebase Authentication and Firestore Tutorial - Crypto Wallet',
		category: 'Youtube',
		description: 'Learn how to create a full stack Flutter application using Firebase authentication, Firestore, and open source APIs. You will learn to hook up APIs into a Flutter application, use Firebase authentication, and implement CRUD operations with Firestore.',
		url: 'https://www.youtube.com/watch?v=fi2WkznwWbc',
		img: require('@/assets/images/free-code-camp-video.avif'),
	},
	{
		id: 2,
		date: 'July 17th, 2020',
		cta: 'Read Article',
		title: 'File and Folder Structure in Flutter',
		category: 'Blog',
		description: 'One of the first things that I realized building apps in Flutter is that I started to accumulate a massive amount of files in my project. As I started working on more projects, I started to get better about keeping my files and folders organized and trying to establish a general pattern and way of organizing my project.',
		url: 'https://medium.com/flutter-community/file-and-folder-structure-in-flutter-967b8be3155e',
		img: require('@/assets/images/file-structure-blog.avif'),
	},
	{
		id: 1,
		date: 'August 11th, 2020',
		cta: 'Read Article',
		title: 'Full-Stack Flutter: Creating a Backend for Your App With AWS & Serverless',
		category: 'Blog',
		description: 'Being such a popular language for new developers, many of those using Flutter don’t know how or where to even begin when it comes to setting up a backend for their applications.',
		url: 'https://itnext.io/full-stack-flutter-creating-a-backend-for-your-app-with-aws-serverless-6ee11ae7bd89',
		img: require('@/assets/images/serverless-blog.webp'),
	},
];

export default projects;
