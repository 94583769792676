<script>
import projects from '../../data/projects';

export default {
	props: ['projectImages'],
	data: () => {
		return {
			projects,
		};
	},
};
</script>

<template>
	<div class="mx-auto my-12 px-4">
		<div class="md:flex justify-between items-center mb-8">
			<div>
				<h2 class="text-2xl md:text-3xl font-bold text-gray-800 dark:text-ternary-light">
					Recent Work:
				</h2>
			</div>
			<div class="mt-2">
				<router-link
					to="/projects"
					class="py-2 text-indigo-600 dark:text-ternary-light text-sm hover:underline mt-4"
				>
					Browse All <span>&rarr;</span> 
				</router-link>
			</div>
		</div>
		<div class="grid md:grid-flow-col md:grid-cols-3 md:grid-rows-1 gap-4">
			<div class="rounded-2xl border md:shadow w-full overflow-hidden">
				<div v-if="projects[0].url.includes('https')">
					<a :href="projects[0].url">
						<img class="w-full h-48 object-cover" :src="projects[0].img" alt="card 1" />
					</a>
				</div>
				<div v-else>
					<router-link :to="{path: '/blog/' + projects[0].title.replace(/\s/g, '-')}">
						<img class="w-full h-48 object-cover" :src="projects[0].img" alt="card 1" />
					</router-link>
				</div>
				<div class="px-4 py-4">
					<div class="text-xs text-gray-600 dark:text-ternary-light font-medium">
						{{ projects[0].category }}
						<span class="mx-1">&bull;</span>
						<span>{{ projects[0].date }}</span>
					</div>

					<h3 class="font-semibold text-gray-800 dark:text-ternary-light my-4 hover:underline text-lg">
						<div v-if="projects[0].url.includes('https')">
							<a :href="projects[0].url">
								{{ projects[0].title }}
							</a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[0].title.replace(/\s/g, '-')}">
								{{ projects[0].title }}
							</router-link>
						</div>
					</h3>

					<div class="text-gray-700 dark:text-ternary-light">
						{{ projects[0].description }}
					</div>

					<div class="mt-4">
						<div v-if="projects[0].url.includes('https')">
							<a :href="projects[0].url" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline"> {{ projects[0].cta }} <span>&rarr;</span></a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[0].title.replace(/\s/g, '-')}" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline">
								{{ projects[0].cta }} <span>&rarr;</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="rounded-2xl border md:shadow w-full overflow-hidden">
				<div v-if="projects[1].url.includes('https')">
					<a :href="projects[1].url">
						<img class="w-full h-48 object-cover" :src="projects[1].img" alt="card 1" />
					</a>
				</div>
				<div v-else>
					<router-link :to="{path: '/blog/' + projects[1].title.replace(/\s/g, '-')}">
						<img class="w-full h-48 object-cover" :src="projects[1].img" alt="card 1" />
					</router-link>
				</div>
				<div class="px-4 py-4">
					<div class="text-xs text-gray-600 dark:text-ternary-light font-medium">
						{{ projects[1].category }}
						<span class="mx-1">&bull;</span>
						<span>{{ projects[1].date }}</span>
					</div>

					<h3 class="font-semibold text-gray-800 dark:text-ternary-light my-4 hover:underline text-lg">
						<div v-if="projects[1].url.includes('https')">
							<a :href="projects[1].url">
								{{ projects[1].title }}
							</a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[1].title.replace(/\s/g, '-')}">
								{{ projects[1].title }}
							</router-link>
						</div>
					</h3>

					<div class="text-gray-700 dark:text-ternary-light">
						{{ projects[1].description }}
					</div>

					<div class="mt-4">
						<div v-if="projects[1].url.includes('https')">
							<a :href="projects[1].url" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline"> {{ projects[1].cta }} <span>&rarr;</span></a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[1].title.replace(/\s/g, '-')}" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline">
								{{ projects[1].cta }} <span>&rarr;</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="rounded-2xl border md:shadow w-full overflow-hidden">
				<div v-if="projects[2].url.includes('https')">
					<a :href="projects[2].url">
						<img class="w-full h-48 object-cover" :src="projects[2].img" alt="card 1" />
					</a>
				</div>
				<div v-else>
					<router-link :to="{path: '/blog/' + projects[2].title.replace(/\s/g, '-')}">
						<img class="w-full h-48 object-cover" :src="projects[2].img" alt="card 1" />
					</router-link>
				</div>
				<div class="px-4 py-4">
					<div class="text-xs text-gray-600 dark:text-ternary-light font-medium">
						{{ projects[2].category }}
						<span class="mx-1">&bull;</span>
						<span>{{ projects[2].date }}</span>
					</div>

					<h3 class="font-semibold text-gray-800 dark:text-ternary-light my-4 hover:underline text-lg">
						<div v-if="projects[2].url.includes('https')">
							<a :href="projects[2].url">
								{{ projects[2].title }}
							</a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[2].title.replace(/\s/g, '-')}">
								{{ projects[2].title }}
							</router-link>
						</div>
					</h3>

					<div class="text-gray-700 dark:text-ternary-light">
						{{ projects[2].description }}
					</div>

					<div class="mt-4">
						<div v-if="projects[2].url.includes('https')">
							<a :href="projects[2].url" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline"> {{ projects[2].cta }} <span>&rarr;</span></a>
						</div>
						<div v-else>
							<router-link :to="{path: '/blog/' + projects[2].title.replace(/\s/g, '-')}" class="py-2 text-indigo-600 dark:text-ternary-light text-xs uppercase hover:underline">
								{{ projects[2].cta }} <span>&rarr;</span>
							</router-link>
						</div>					</div>
				</div>
			</div>
		</div>
	</div>
</template>
