const dates = [
	{ date: '2023-6-14', count: 5 },
	{ date: '2023-5-2', count: 5 },
	{ date: '2023-4-27', count: 3 },
	{ date: '2023-4-24', count: 3 },
	{ date: '2023-4-20', count: 3 },
	{ date: '2023-4-17', count: 3 },
	{ date: '2023-4-13', count: 3 },
	{ date: '2023-4-10', count: 3 },
	{ date: '2023-4-6', count: 3 },
	{ date: '2023-4-3', count: 3 },
	{ date: '2023-3-30', count: 3 },
	{ date: '2023-3-24', count: 3 },
	{ date: '2023-2-23', count: 3 },
];

export default dates;