export const socialLinks = [
	{
		id: 1,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/Bobleyl',
	},
	{
		id: 2,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/bleyldev',
	},
	{
		id: 3,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/bobleyl',
	},
	{
		id: 4,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/c/bleyldev',
	},
];
