<script>
import feather from 'feather-icons';
import projects from '../../data/projects';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';

export default {
	data: () => {
		return {
			projects,
			searchProject: '',
			selectedCategory: '',
		};
	},
	components: { ProjectSingle, ProjectsFilter, },
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				return category.includes(this.selectedCategory);
			});
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<div>
		<div class="md:flex justify-between items-center mb-8 mt-16">
				<div>
					<h2 class="text-2xl md:text-3xl font-bold text-gray-800 dark:text-ternary-light mx-4 pb-4 sm:pb-0">
						Recent Work:
					</h2>
				</div>
				<div class="flex justify-between gap-2">
					<input
						v-model="searchProject"
						class="font-general-medium
						pl-3
						pr-1
						sm:px-4
						py-2
						border-1 border-gray-200
						dark:border-secondary-dark
						rounded-lg
						text-sm
						sm:text-md
						bg-secondary-light
						dark:bg-ternary-dark
						text-primary-dark
						dark:text-ternary-light
						"
						id="name"
						name="name"
						type="search"
						required=""
						placeholder="Search Work"
						aria-label="Name"
					/>
					<span
						class="
							hidden
							sm:block
							bg-primary-light
							dark:bg-ternary-dark
							p-2.5
							shadow-sm
							rounded-xl
							cursor-pointer
							"
					>
						<i
							data-feather="search"
							class="text-ternary-dark dark:text-ternary-light"
						></i>
					</span>
					<ProjectsFilter @filter="selectedCategory = $event" class="pr-8" />
				</div>
			</div>
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/>
		</div>
	</div>
</template>

<style scoped></style>
